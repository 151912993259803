/* Toastify */
.Toastify .fancy-progress-bar {
  background: rgba(255, 255, 255, 0.45);
}
.Toastify .Toastify__toast {
  background-color: #000;
}
.Toastify .Toastify__toast .Toastify__toast-body,
.Toastify .Toastify__toast .Toastify__close-button {
  color: #ffffff;
}
.Toastify .Toastify__toast .Toastify__close-button {
  opacity: 0.7;
}
.Toastify .Toastify__toast .Toastify__close-button:hover {
  opacity: 1;
}
@media (max-width: 480px) {
  .Toastify .Toastify__toast-container--top-left,
  .Toastify .Toastify__toast-container--top-center,
  .Toastify .Toastify__toast-container--top-right {
    top: 66px;
  }
}

/* Drawer */
.drawer-search {
  transform: translate(-50%, 50px);
}
.drawer-search.open {
  @apply fixed top-0 visible block opacity-100;
  transform: translate(-50%, 0);
}
.overlay {
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 21;
  visibility: hidden;
  @apply w-full;
  @apply h-full;
  @apply flex;
  @apply fixed;
  @apply opacity-0;
  @apply top-0;
  @apply left-0;
  transition: 0.35s ease-in-out;
}
.overlay.open {
  @apply opacity-100;
  visibility: visible;
}

/* Form Crafts */
.fc__iframe {
  box-shadow: none !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
