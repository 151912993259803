/*.pagination .ais-Pagination-list {
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  margin: 0 auto;
}
.ais-Pagination-link {
  padding: 0.3rem 0.6rem;
  display: block;
  border: 1px solid #c4c8d8;
  border-radius: 5px;
  transition: background-color 0.2s ease-out;
}

.ais-Pagination-item {
  margin-left: 0.3rem;
}

.ais-Pagination-item--selected .ais-Pagination-link {
  color: #fff;
  background-color: #0096db;
  border-color: #0096db;
}
*/

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}
